import React, {useEffect} from 'react';
import axios from 'axios';
import plconfig from '../config.js'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Alert, AlertTitle } from '@material-ui/lab';
import Badge from '@material-ui/core/Badge';


export default function Subscribe(props) {

	const [isValid, setIsValid] = React.useState(true);
	const [isSaveOk, setIsSaveOk] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const [unsubscribeOk, setUnsubscribeOk] = React.useState(false);

	const [selectedProductGroup, setSelectedProductGroup] = React.useState("");
	const [subscribeObj, setSubscribeObj] = React.useState({
		email: '',
		products: []
	});

	const handleSubcribeInputChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setSubscribeObj({
			...subscribeObj,
			[event.target.id]: event.target.value
		});
	};

	const handleSubscribe = () => {
        setIsSaveOk(false)
        setIsError(false)
        setIsValid(true)
        setUnsubscribeOk(false)

        let isValid = true;
        if (!subscribeObj) {
            isValid = false;
        }
        if (!subscribeObj.email) {
            isValid = false;
        }
        if (!subscribeObj.products) {
            isValid = false;
        }
        if (isValid) {
            axios.post(plconfig.APIBase + 'apps/notifications?action=subscribe', {
                subscribe: subscribeObj,
                chainid: props.chainid
            })
            .then(function (response) {
                if (response.data.success) {
                    setIsSaveOk(true)
                } else {
                    setIsError(true)
                }
            })
            .catch(function (error) {
                console.error(error)
            });
        } else {
            setIsValid(false)
            setIsError(true)
        }
    }

    const handleUnSubscribe = () => {
        if (confirm('Halautko varmasti peruuttaa ilmoitusten tilaamisen?')) {
            axios.post(plconfig.APIBase + 'apps/notifications?action=unsubscribe', {
                chainid: props.chainid
            })
            .then(function (response) {
                if (response.data.success) {
                    setSubscribeObj(response.data.subscribe)
                    setUnsubscribeOk(true)
                }
            })
            .catch(function (error) {
                console.error(error)
            });
        }
    }

	const selectProductGroup = (productgroup) => {
		setSelectedProductGroup(productgroup.id);
		//console.log(productgroup.id)
	};
	const selectProduct = (product) => {
        const value = product.id;
		let products = [...subscribeObj.products];
		if (!products) {
			products = [];
		}
		if (products.includes(value)) {
			products.splice(products.indexOf(value), 1)
		} else {
			products.push(value);
		}
		setSubscribeObj({
			...subscribeObj,
			"products": products
		})
        
    }

	const checkproductgroupcount = (productgroup) => {
		var count = 0
		if (subscribeObj.products) {
			for (const product in props.productObj) {
				if (props.productObj[product].GROUP === productgroup.id) {
					if (subscribeObj.products.includes(props.productObj[product].id)) {
						count++
					}
				}
			}

		}
		return <Badge color="secondary" badgeContent={count} />
	}

	useEffect(() => {
		if (props.chainid) {
			axios.post(plconfig.APIBase + 'apps/notifications?action=get', {
				chainid: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setSubscribeObj(response.data.subscribe);
				}
			})
			.catch(function(error) {
				console.error(error)
			});
		}
	}, [props.chainid])
	
	return (
		<>
					
			<h3 className={props.classes.mainHeader}>Tilaa ilmoituksia</h3>
			<Typography className={props.classes.maintText}>Tältä sivulta voitte tilata ilmoituksia tuottajien ilmoittamista uusista tuotantoeristä.</Typography>

			{isSaveOk &&
			<Alert severity="success">
				<AlertTitle>Tilaus tallennettu</AlertTitle>
				Organisaation tilaus ilmoituksista on nyt tallennettu
			</Alert>
			}

			{isError &&
			<Alert severity="error" >
				<AlertTitle>Lomakkeen tallennus epäonnistui</AlertTitle>
				Tarkasta että kaikki tiedot on syötetty ja kokeile uudestaan!
			</Alert>
			}

			{unsubscribeOk &&
			<Alert severity="success">
				<AlertTitle>Tilaus peruttu</AlertTitle>
				Organisaation tilaus ilmoituksista on nyt peruttu.
			</Alert>
			}
			
			<Grid container spacing={2} className={props.classes.productGrid}>
				<Grid item xs={3} className={props.classes.productColumn1}>
					<Typography className={props.classes.productHeader}>Tuoteryhmät</Typography>
					<List component="nav" aria-label="Luomutuoteryhmät" dense={false}>
						{(props.productGroupsObj) &&
							<React.Fragment>
							{props.productGroupsObj.map((productgroup) =>
								<ListItem key={productgroup.id} button selected={selectedProductGroup===productgroup.id} onClick={() => selectProductGroup(productgroup)}><ListItemText primary={productgroup.NAME} />
									{checkproductgroupcount(productgroup)}
								</ListItem>
							)}
							</React.Fragment>
						}
					</List>	
				</Grid>
				<Grid item xs={3} className={props.classes.productColumn2}>
					<Typography className={props.classes.productHeader}>Tuotteet</Typography>
					<List component="nav" aria-label="Luomutuotteet" dense={false}>
						{(props.productObj) &&
							<React.Fragment>
							{props.productObj.map((product) =>
								<React.Fragment>
									{product.GROUP === selectedProductGroup &&
										<ListItem button selected={subscribeObj.products && subscribeObj.products.includes(product.id)} onClick={() => selectProduct(product)}><ListItemText primary={props.capitalizeFirstLetter(product.NAME)} /></ListItem>
									}
								</React.Fragment>
							)}
							</React.Fragment>
						}
					</List>	
				</Grid>
			</Grid>

			<TextField
				id="email"
				label="Sähköpostiosoite jonne ilmoitukset lähetetään *"
				variant="outlined"
				className={props.classes.formInputMarginT}
				type="text"
				value={subscribeObj.email}
				fullWidth
				error={!isValid && subscribeObj.email==""}
				onChange={handleSubcribeInputChange}
				InputLabelProps={{
					shrink: true,
				}}
			/>	

			<hr/>

			<Grid container className={props.classes.bottomGrid}>
				<Grid item xs={8}>
					<Button type="button" variant="contained" color="primary" className={props.classes.saveButton} onClick={handleSubscribe}>Tallenna tilaus</Button>
				</Grid>
				{(subscribeObj.chainid) &&
				<Grid item xs={4} className={props.classes.bottomGridRight}>
					<Button type="button" variant="outlined" color="secondary" className={props.classes.dangerButton} onClick={handleUnSubscribe}>Peru tilaus</Button>
				</Grid>
				}
			</Grid>	

		</>

	);
}
