import React, {useEffect} from 'react';
import {Prompt} from "react-router-dom";
import axios from 'axios';
import moment from "moment";
import "moment/locale/fi";
import { useAuth } from '../Auth/Auth.js'

import plconfig from '../config.js'

import OrganicGoal from "./OrganicGoal.js"
import CriticalPoints from "./CriticalPoints.js"
import WildProducts from "./WildProducts.js"
import Producers from './Producers.js';
import Products from './Products.js';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FileInput from '../Components/FileInput.js';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkButton from '../Components/LinkButton.js'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';
import { ContactSupportOutlined } from '@material-ui/icons';



const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: theme.spacing(5)
	},
	topGrid: {
		marginBottom: theme.spacing(2)
	},
	bottomGrid: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(3),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	bottombutton1: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(2)
	},
	bottombutton2: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(2),
		color: '#fff'
	},
	bottombutton3: {
		marginTop: theme.spacing(3),
		marginRight: theme.spacing(3)
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(3)
	},
	linkcursor: {
		cursor: 'pointer'
	},
	tabItem: {
		backgroundColor: theme.palette.primary.main,
		color: '#fff',
		borderColor: theme.palette.primary.main,
		'&:focus': {
			outline: 0,
		},
	},
	tabItemError: {
		backgroundColor: "#fdecea",
		color: "#f44336",
		borderColor: "#fdecea",
		'&:focus': {
			outline: 0,
		},
	},
	tabItemError2: {
		color: "#f44336",
	},
	tabPanel: {
		padding: theme.spacing(3),
		minHeight: 300
	},
	productColumn1: {
		backgroundColor: "#efeeee",
	},
	productColumn2: {
		backgroundColor: "#f7f7f7",
	},
	productColumn3: {
		backgroundColor: "#ffffff",
	},
	formInput: {
		marginBottom: theme.spacing(3),
		width: '100%'
	},
	formInputSmall: {
		marginBottom: theme.spacing(3),
		width: '33%'
	},
	productHeader: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(1),
		fontWeight: 600
	},
	productHeader2: {
		marginTop: theme.spacing(0),
		marginLeft: theme.spacing(0),
		marginRight: theme.spacing(0),
		marginBottom: theme.spacing(3),
		fontWeight: 600
	},
	productForm: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	extraInfoText: {
		paddingTop: theme.spacing(1),
	},
	extraInfoText2: {
		paddingBottom: theme.spacing(2),
	},
	commonAlert: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		marginBottom: theme.spacing(2)
	},
	infogrid: {
		marginBottom: theme.spacing(2)
	},
	infogridright: {
		marginTop: theme.spacing(5),
		paddingLeft: theme.spacing(3),
		textAlign: 'right'
	},
	modalPaper: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 800,
		height: 350,
		backgroundColor: theme.palette.background.paper,
		border: '4px solid #c3c3c3',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
	modalCloseButton: {
		position: 'absolute',
		top: 5,
		right: 5,
	},
	HelpIconButton: {
		padding: 0,
	},
	deleteallbutton: {
		color: "#f44336",
		borderColor: "#f44336",
		marginLeft: theme.spacing(3)
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <Typography
		component="div"
		role="tabpanel"
		hidden={value !== index}
		id={`full-width-tabpanel-${index}`}
		aria-labelledby={`full-width-tab-${index}`}
		{...other}
	  >
		{value === index && <Box>{children}</Box>}
	  </Typography>
	);
}

function capitalizeFirstLetter(string) {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} else {
		return string
	}
	
}

export default function Plan(props) {

	const classes = useStyles();

	const {isAuthenticated, setIsAuthenticated, userData, setUserData} = useAuth();
	const [planSaved, setPlanSaved] = React.useState(true);
	const [selectedTab, setSelectedTab] = React.useState(0);

	const [readOnly, setReadOnly] = React.useState(false);
	
	let examcount = 0;

	const [isFetchingData, setIsFetchingData] = React.useState(true);
	const [isValid, setIsValid] = React.useState(true);
	const [isSaveOk, setIsSaveOk] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [isPhase1Valid, setIsPhase1Valid] = React.useState(true);
	const [isPhase2Valid, setIsPhase2Valid] = React.useState(true);
	const [isPhase3Valid, setIsPhase3Valid] = React.useState(true);
	const [isPhase4Valid, setIsPhase4Valid] = React.useState(true);
	const [isPhase5Valid, setIsPhase5Valid] = React.useState(true);
	const [isPhase6Valid, setIsPhase6Valid] = React.useState(true);

	const [planData, setPlanData] = React.useState({
		PRODUCERS: [],
		CRITICAL1: [],
		CRITICAL2: [],
		CRITICAL3: [],
		CRITICAL4: [],
		CRITICAL5: [],
		CRITICAL6: [],
		APPLYFORSTEP: '',
		DECISIONMUNICIPALITY : false, 
		DECISIONORGANISATION : false
	});

	
	// TAB CHANGED HANDLERS
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};
	const handleNext = (newValue) => {
		setSelectedTab(newValue);
	};


	// COMMON INPUT HANDLERS
	const handleTextInputChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setPlanData({
			...planData,
			[event.target.id]: event.target.value
		});
		setPlanSaved(false)
	};
	const handleSelectInputChange = (event) => {
		//console.log(event.target.name + ' : ' + event.target.value)
		setPlanData({
			...planData,
			[event.target.name]: event.target.value
		});
		setPlanSaved(false)
	};
	const handlePublicPrivateChange = (event) => {
		setPlanData({
			...planData,
			[event.target.id]: event.target.value
		});
		setPlanSaved(false)
	};
	const handleDateChange = (date) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setPlanData({
			...planData,
			[event.target.id]: event.target.value
		});
		setPlanSaved(false)
	};
	const handleSwitchChange = (event) => {
		if (!readOnly) {
			//console.log(event.target.id + ' : ' + event.target.checked)
			setPlanData({
				...planData,
				[event.target.id]: event.target.checked
			});
			setPlanSaved(false)
		}
	};

	const handleMakeNewPlan = () => {
		const clone = JSON.parse(JSON.stringify(planData));
		clone.id = 'new';
		clone.READY = false;
		setPlanData(clone);
		setReadOnly(false);
	}

	// HANDLE SAVE
	const validateForm = () => {
		let isFormValid = true;

		setIsPhase1Valid(true)
		setIsPhase2Valid(true)
		setIsPhase3Valid(true)
		setIsPhase4Valid(true)
		setIsPhase5Valid(true)
		setIsPhase6Valid(true)

		if (planData.APPLYFORSTEP=="" ||
			planData.CRITERIAFORSTEP=="" || 
			planData.DECISIONPERCENT=="") {
			setIsPhase1Valid(false);
			isFormValid = false;
		}

		if (planData.FAMILIARIZATION=="") {
			setIsPhase2Valid(false);
			isFormValid = false;
		}

		if (planData.ORGANICINGREDIENTS.length<1 ||
			planData.TOTALUSAGE=="") {
			setIsPhase3Valid(false);
			isFormValid = false;
		}

		if (planData.PRODUCERS.length<1) {
			setIsPhase5Valid(false);
			isFormValid = false;
		} else {
			for (const producerindex in planData.PRODUCERS) {
				let producerdatestr = planData.PRODUCERS[producerindex].PRODUCERVALIDITY
				let producerdate = moment(producerdatestr)
				//console.log("cc: " + producerdatestr)				
				//console.log(producerdate)
				// this date is before producers date
				if (producerdatestr=="Invalid date") {
					setIsPhase5Valid(false);
					isFormValid = false;
				} else {
					if (moment().isBefore(producerdate)) {
						setIsPhase5Valid(false);
						isFormValid = false;
					}
				}
				
				//console.log(isFormValid)
			}
		}

		if (planData.CRITICAL1.length<1 ||
			planData.CRITICAL2.length<1 ||
			planData.CRITICAL3.length<1 ||
			planData.CRITICAL4.length<1 ||
			planData.CRITICAL5.length<1 ||
			planData.CRITICAL6.length<1) {
			setIsPhase6Valid(false);
			isFormValid = false;
		}

		if (isPhase1Valid && isPhase2Valid && isPhase3Valid && isPhase4Valid && isPhase5Valid && isPhase6Valid) {
		} else {
			isFormValid = false;
		}
		return isFormValid
	}

	const validateAndSaveAndSend = () => {
		
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		if (validateForm()) {
			// if valid and sending to agreement then change to ready
			let sendData = {...planData}
			sendData.READY = true;
			// correct producers date
			if (sendData.PRODUCERS) {
				for (const producerindex in sendData.PRODUCERS) {
					sendData.PRODUCERS[producerindex].PRODUCERVALIDITY = moment(sendData.PRODUCERS[producerindex].PRODUCERVALIDITYDATE).format("DD.MM.YYYY")
				}
			}

			//console.log(sendData)

			axios.post(plconfig.APIBase + 'apps/plan.groovy?action=save', sendData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsError(false)
					setIsSaveOk(true)
					setIsValid(true)
					setIsPhase1Valid(true);
					setIsPhase2Valid(true);
					setIsPhase3Valid(true);
					setIsPhase4Valid(true);
					setIsPhase5Valid(true);
					setIsPhase6Valid(true);
					setPlanSaved(true)

				} else {
					setIsSaveOk(false)
					setIsError(true)
					setIsValid(false)
				}
			})
			.catch(function(error) {
				setIsSaveOk(false)
				setIsError(true)
			});

		} else {
			setIsSaveOk(false)
			setIsError(true)
			setIsValid(false)
		}
		
	}


	const handleSave = (event) => {
		event.preventDefault()
		//console.log(planData)

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		// correct producers date
		let sendData = {...planData}
		if (sendData.PRODUCERS) {
			for (const producerindex in sendData.PRODUCERS) {
				sendData.PRODUCERS[producerindex].PRODUCERVALIDITY = moment(sendData.PRODUCERS[producerindex].PRODUCERVALIDITYDATE).format("DD.MM.YYYY")
			}
		}

		axios.post(plconfig.APIBase + 'apps/plan.groovy?action=save', sendData, { headers: { "Content-Type": "text/plain" } })
		.then(function (response) {
			if (response.data.success) {
				setPlanData({
					...planData,
					["id"]: response.data.plan.id
				});
				setIsSaveOk(true)
				setIsError(false)
				setPlanSaved(true)
			} else {
			}
		})
		.catch(function(error) {
			setIsSaveOk(false)
			setIsError(true)
		});

	}

	const handleSetNotReady = (event) => {
		event.preventDefault()

		if (confirm("Haluatko varmasti palauttaa suunnitelman tilaan kesken?")) {

			let sendData = {...planData}

			axios.post(plconfig.APIBase + 'apps/plan.groovy?action=notready', sendData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsSaveOk(true)
					setIsError(false)
					planData.READY = false;
					setSelectedTab(1)
					setSelectedTab(0)
					setReadOnly(false);
				} else {
				}
			})
			.catch(function(error) {
				setIsSaveOk(false)
				setIsError(true)
			});

		}

	}

	// LOAD DATA ON START
	useEffect(() => {
		if (props.chainid) {

			axios.post(plconfig.APIBase + 'apps/dashboard.groovy?action=chaininfo', {
				chainid: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					let needcopy = false;
					let planid = ''

					//console.log(response.data)
					
					// if draft then use it else make copy of current and use it
					if (response.data.chain.draftplan && response.data.chain.draftplan.id) {
						//console.log('draft found')
						planid = response.data.chain.draftplan.id;
					} else {
						if (response.data.chain.currentplan && response.data.chain.currentplan.id) {
							//console.log('current found')
							planid = response.data.chain.currentplan.id;
							//console.log(planid)
							needcopy = true;
						}
					}

					if (response.data.exam) {
						examcount = response.data.exam
					}

					if (planid!=='') {
						//console.log("fetching plan " + planid)
						axios.post(plconfig.APIBase + 'apps/plan.groovy?action=info', {
							id: planid
						}, { headers: { "Content-Type": "text/plain" } })
						.then(function (response) {
							if (response.data.success) {
								setIsFetchingData(false)

								let pd = response.data.plan;
								if (pd.PRODUCERS) {
									for (const producerindex in pd.PRODUCERS) {
										pd.PRODUCERS[producerindex].PRODUCERVALIDITYDATE = moment(pd.PRODUCERS[producerindex].PRODUCERVALIDITY, "DD.MM.YYYY")
									}
								}

								if (examcount>0) {
									pd.FAMILIARIZATION = "" + examcount
								}
								// if plan is ready and agreed set it to readonly mode
								if (pd.READY && pd.AGREEMENDDATE) {
									setReadOnly(true);
								}
								setPlanData(pd);
								
							} else {
								setIsFetchingData(true)
							}
						})
						.catch(function(error) {
							setIsFetchingData(true)
						});
					} else {
						// create new plan
						setIsFetchingData(false)
						setPlanData({
							id: 'new',
							FAMILIARIZATION: "" + examcount,
							CRITERIAFORSTEP: "",
							TOTALUSAGE: "0",
							ORGANICINGREDIENTS: [],
							WILDPRODUCTS: [],
							OTHERORGANICINGREDIENTS: "", 
							ORGANICINGREDIENTSADDPLAN_NEXT : "", 
							ORGANICINGREDIENTSADDPLAN_TYPE : "",
							ORGANICINGREDIENTSADDPLAN_NEED : "",
							AGREEORGANICINGREDIENTSPUBLISH : false,
							ORGANICINGREDIENTSKILOS : "0", 
							ORGANICINGREDIENTSEUROS : "0",
							PLANDEVELOPMENT: "",
							DECISIONMUNICIPALITY : false, 
							DECISIONORGANISATION : false,
							DECISIONYEAR : "",
							FOODTENDERING_PRODUCTS : "",
							FOODTENDERING_START : "",
							FOODTENDERING_TIME : "",
							READY: false,
							CHAIN: props.chainid, 
							PRODUCERS: [],
							CRITICAL1: [],
							CRITICAL2: [],
							CRITICAL3: [],
							CRITICAL4: [],
							CRITICAL5: [],
							CRITICAL6: [],
							APPLYFORSTEP: ''
						})
					}

				} else {
					setIsFetchingData(true)
				}
		
				return response.data;
			})
			.catch(function(error) {
				setIsFetchingData(true)
			});


			
		}
	}, [props.chainid])

	// This is user several tabs so load here and pass for childs
	const [productsdb, setProductsdb] = React.useState([]);
	useEffect(() => {
		if (props.chainid) {
			axios.get(plconfig.APIBase + 'apps/api_getproducts.groovy', { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setProductsdb(response.data)
				} else {
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])


	return (
		<Container maxWidth="xl" className={classes.container}>

			<Prompt
				when={!planSaved}
				message={location =>
				`Olet poistumassa luomusuunnitelman muokkauksesta mutta et ole tallentanut muutoksia! Haluatko varmasti poistua tallentamatta? ${location.pathname}`
				}
			/>

			{(planData.id && planData.id=='new') &&
				<h4 className={classes.PaperHeader}>Luomusuunnitelma - Uusi suunnitelma</h4>
			}
			
			{(planData.id && planData.id!=='new') &&
				<>
					{(readOnly) 
						? <h4 className={classes.PaperHeader}>Luomusuunnitelma - Luotu {planData.created ? planData.created : ''}</h4>
						: <h4 className={classes.PaperHeader}>Luomusuunnitelma - {planData.created ? planData.created : ''} luotu vedos</h4>
					}
				</>
			}

			{(!planData.READY) || (userData.aadms) ?
				<Paper className={classes.paperTopGrid}>
					<form onSubmit={handleSave}>
					<Tabs value={selectedTab} onChange={handleChange} indicatorColor="primary" variant="fullWidth" aria-label="Luomusuunnitelmalomakkeen vaiheet">
						<Tab label="Luomutavoite" className={selectedTab===0 ? (!isPhase1Valid ? classes.tabItemError : classes.tabItem) : (!isPhase1Valid ? classes.tabItemError2 : classes.tabItem2)} />
						<Tab label="Luomutuotteet" className={selectedTab===1 ? (!isPhase3Valid ? classes.tabItemError : classes.tabItem) : (!isPhase3Valid ? classes.tabItemError2 : classes.tabItem2)} />
						<Tab label="Villituotteet" className={selectedTab===2 ? (!isPhase4Valid ? classes.tabItemError : classes.tabItem) : (!isPhase4Valid ? classes.tabItemError2 : classes.tabItem2)} />
						<Tab label="Toimittajat" className={selectedTab===3 ? (!isPhase5Valid ? classes.tabItemError : classes.tabItem) : (!isPhase5Valid ? classes.tabItemError2 : classes.tabItem2)} />
						<Tab label="Kriittiset pisteet" className={selectedTab===4 ? (!isPhase6Valid ? classes.tabItemError : classes.tabItem) : (!isPhase6Valid ? classes.tabItemError2 : classes.tabItem2)} />
					</Tabs>

					{(readOnly) &&
					<Alert severity="info" className={classes.commonAlert}>
						<AlertTitle>Suunnitelma valmis</AlertTitle>
						<>Tämä suunnitelma on hyväksytty ja on pelkästään katseltavissa. Mikäli haluat päivittää suunnitelman paina alla olevaa painiketta. Nykyinen suunnitelma tulee uuden suunnitelman pohjaksi.
						{(planData.READY && userData.aadms) &&
							<> <i>Voit ylläpitäjänä <Link className={classes.linkcursor} onClick={handleSetNotReady}>palauttaa lomakkeen täytettäväksi.</Link></i><br/></>
						}
						</>
						<Button variant="contained" color="secondary" size="small" className={classes.bottombutton3} onClick={handleMakeNewPlan}>Päivitä luomusuunnitelma</Button>
					</Alert>
					}

					{isSaveOk &&
					<Alert severity="success" className={classes.commonAlert}>
						<AlertTitle>Tallennettu</AlertTitle>
						Suunnitelman tiedot on nyt tallennettu
					</Alert>
					}

					{isError &&
					<Alert severity="error" className={classes.commonAlert}>
						<AlertTitle>Lomakkeen tallennus epäonnistui</AlertTitle>
						Tarkasta että kaikki tiedot on syötetty ja kokeiles uudestaan!
					</Alert>
					}

					<TabPanel value={selectedTab} index={0} className={classes.tabPanel}>

						<OrganicGoal classes={classes} planData={planData} isValid={isValid} handleSelectInputChange={handleSelectInputChange} handleTextInputChange={handleTextInputChange} handleSwitchChange={handleSwitchChange} readOnly={readOnly} />

						<Grid container spacing={3} className={classes.bottomGrid2}>
							<Grid item xs={8}>
							</Grid>
							<Grid item xs={4} className={classes.bottomGridRight}>
								<Button variant="outlined" className={classes.bottombutton3} onClick={() => handleNext(1)}>Luomutuotteet<ChevronRightIcon/> </Button>
							</Grid>
						</Grid>	

					</TabPanel> 

					<TabPanel value={selectedTab} index={1} className={classes.tabPanel}>

						<Products chainid={props.chainid} classes={classes} planData={planData} setPlanData={setPlanData} isValid={isValid} setPlanSaved={setPlanSaved} productsdb={productsdb} capitalizeFirstLetter={capitalizeFirstLetter} handleTextInputChange={handleTextInputChange} readOnly={readOnly} />

						<Grid container spacing={3} className={classes.bottomGrid2}>
							<Grid item xs={8}>
							</Grid>
							<Grid item xs={4} className={classes.bottomGridRight}>
								<Button variant="outlined" className={classes.bottombutton3} onClick={() => handleNext(2)}>Villi- ja keruutuotteet <ChevronRightIcon/> </Button>
							</Grid>
						</Grid>	

					</TabPanel>
					<TabPanel value={selectedTab} index={2} className={classes.tabPanel}>

						<WildProducts chainid={props.chainid} classes={classes} planData={planData} setPlanData={setPlanData} isValid={isValid} setPlanSaved={setPlanSaved} productsdb={productsdb} capitalizeFirstLetter={capitalizeFirstLetter} readOnly={readOnly} />

						<Grid container spacing={3} className={classes.bottomGrid2}>
							<Grid item xs={8}>
							</Grid>
							<Grid item xs={4} className={classes.bottomGridRight}>
								<Button variant="outlined" className={classes.bottombutton3} onClick={() => handleNext(3)}>Toimittajat <ChevronRightIcon/> </Button>
							</Grid>
						</Grid>	


					</TabPanel>
					<TabPanel value={selectedTab} index={3} className={classes.tabPanel}>
						
						<Producers chainid={props.chainid} classes={classes} planData={planData} setPlanData={setPlanData} isValid={isValid} setPlanSaved={setPlanSaved} productsdb={productsdb} capitalizeFirstLetter={capitalizeFirstLetter} readOnly={readOnly} />

						<Grid container spacing={3} className={classes.bottomGrid2}>
							<Grid item xs={8}>
							</Grid>
							<Grid item xs={4} className={classes.bottomGridRight}>
								<Button variant="outlined" className={classes.bottombutton3} onClick={() => handleNext(4)}>Kriittiset pisteet <ChevronRightIcon/> </Button>
							</Grid>
						</Grid>	


					</TabPanel>
					<TabPanel value={selectedTab} index={4} className={classes.tabPanel}>
						
						<CriticalPoints classes={classes} planData={planData} setPlanSaved={setPlanSaved} setPlanData={setPlanData} handleTextInputChange={handleTextInputChange} readOnly={readOnly} />

					</TabPanel>

						
					<hr/>

					<Grid container spacing={3} className={classes.bottomGrid}>
						<Grid item xs={4}>
							<Button type="submit" variant="contained" color="primary" size="large" className={classes.bottombutton1} disabled={readOnly}>Tallenna</Button>
						</Grid>
						<Grid item xs={4}>
							<Button type="button" variant="contained" color="secondary" size="large" className={classes.bottombutton2} onClick={() => validateAndSaveAndSend()} disabled={readOnly}>Lähetä hyväksyttäväksi</Button>
						</Grid>
						<Grid item xs={4} className={classes.bottomGridRight}>
							<LinkButton to='/dashboard' className={classes.bottombutton3}>Peruuta</LinkButton>
						</Grid>
					</Grid>	

					</form>

			</Paper>
			: 
			<Paper className={classes.paper}>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography className={classes.maintText}>Luomusuunnitelma on lähetetty hyväksyttäväksi joten sitä ei voi muokata.</Typography>
					</Grid>
				</Grid>	

				<Grid container className={classes.bottomGrid}>
					<Grid item xs={8}>
					</Grid>
					<Grid item xs={4} className={classes.bottomGridRight}>
						<LinkButton to='/dashboard' className={classes.cancelButton}>Peruuta</LinkButton>
					</Grid>
				</Grid>	
			</Paper>
			}
		
		</Container>
	);
}
