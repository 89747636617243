import React, {useEffect} from 'react';
import axios from 'axios';
import plconfig from '../config.js'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Alert, AlertTitle } from '@material-ui/lab';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';

export default function Publish(props) {

	const [isValid, setIsValid] = React.useState(true);
	const [isSaveOk, setIsSaveOk] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [publishedListObj, setPublishedListObj] = React.useState(null);

	const [selectedProductGroup, setSelectedProductGroup] = React.useState("");
    const [publishObj, setPublishObj] = React.useState({
        subject: "",
        message: "",
        products: []
    });
	const handlePublishInputChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setPublishObj({
			...publishObj,
			[event.target.id]: event.target.value
		});
	};

	const selectProductGroup = (productgroup) => {
		setSelectedProductGroup(productgroup.id);
		//console.log(productgroup.id)
	};
	const selectProduct = (product) => {
        const value = product.id;
		let products = [...publishObj.products];
		if (!products) {
			products = [];
		}
		if (products.includes(value)) {
			products.splice(products.indexOf(value), 1)
		} else {
			products.push(value);
		}
		setPublishObj({
			...publishObj,
			"products": products
		})
    }

	const handlePublish = () => {
		setIsSaveOk(false)
		setIsError(false)
		setIsValid(true)

		let isValid = true;
		if (!publishObj) {
			isValid = false;
		}
		if (!publishObj.products) {
			isValid = false;
		}
		if (!publishObj.subject) {
			isValid = false;
		}
		if (!publishObj.message) {
			isValid = false;
		}

		if (isValid) {
			if (confirm('Halautko varmasti julkaista ilmoituksen kaikille aktiivisen tilauksen valinneille tuottajille?')) {
				axios.post(plconfig.APIBase + 'apps/notifications?action=publish', {
					publish: publishObj,
					chainid: props.chainid
				})
				.then(function (response) {
					if (response.data.success) {
						setIsSaveOk(true)
						setPublishObj({
							subject: "",
							message: "",
							products: []
						});
					} else {
						setIsError(true)
					}
				})
				.catch(function (error) {
					console.error(error)
				});
			}
		} else {
			setIsValid(false)
			setIsError(true)
		}
	}

	const checkproductgroupcount = (productgroup) => {
		var count = 0
		if (publishObj.products) {
			for (const product in props.productObj) {
				if (props.productObj[product].GROUP === productgroup.id) {
					if (publishObj.products.includes(props.productObj[product].id)) {
						count++
					}
				}
			}

		}
		return <Badge color="secondary" badgeContent={count} />
	}

	useEffect(() => {
		if (!publishedListObj && props.chainid) {
			axios.post(plconfig.APIBase + 'apps/notifications?action=published', {
				chainid: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setPublishedListObj(response.data.published);
				}
			})
			.catch(function(error) {
				console.error(error)
			});
		}
	}, [props.chainid])

	return (
		<>
			<h3 className={props.classes.mainHeader}>Lähetä ilmoituksia</h3>
			<Typography className={props.classes.maintText}>Tältä sivulta voitte luoda omia ilmoituksia tuottajille kertaluontoisista tilaustarpeista.</Typography>

			{isSaveOk &&
			<Alert severity="success">
				<AlertTitle>Ilmoitus julkaistu</AlertTitle>
				Ilmoitus on nyt tallennettu ja julkaistu.
			</Alert>
			}

			{isError &&
			<Alert severity="error">
				<AlertTitle>Ilmoituksen tallennus epäonnistui</AlertTitle>
				Tarkasta että kaikki tiedot on syötetty ja kokeile uudestaan!
			</Alert>
			}

			<Grid container spacing={2} className={props.classes.productGrid}>
				<Grid item xs={3} className={props.classes.productColumn1}>
					<Typography className={props.classes.productHeader}>Tuoteryhmät</Typography>
					<List component="nav" aria-label="Luomutuoteryhmät" dense={false}>
						{(props.productGroupsObj) &&
							<React.Fragment>
							{props.productGroupsObj.map((productgroup) =>
								<ListItem key={productgroup.id} button selected={selectedProductGroup===productgroup.id} onClick={() => selectProductGroup(productgroup)}><ListItemText primary={productgroup.NAME} />
									{checkproductgroupcount(productgroup)}
								</ListItem>
							)}
							</React.Fragment>
						}
					</List>	
				</Grid>
				<Grid item xs={3} className={props.classes.productColumn2}>
					<Typography className={props.classes.productHeader}>Tuotteet</Typography>
					<List component="nav" aria-label="Luomutuotteet" dense={false}>
						{(props.productObj) &&
							<React.Fragment>
							{props.productObj.map((product) =>
								<React.Fragment>
									{product.GROUP === selectedProductGroup &&
										<ListItem button selected={publishObj.products && publishObj.products.includes(product.id)} onClick={() => selectProduct(product)}><ListItemText primary={props.capitalizeFirstLetter(product.NAME)} /></ListItem>
									}
								</React.Fragment>
							)}
							</React.Fragment>
						}
					</List>	
				</Grid>
				<Grid item xs={6}>
					<Typography className={props.classes.productHeader}>Aikaisemmat ilmoitukset</Typography>
					<div className={props.classes.publishList}>

					{publishedListObj &&
						<>
							{publishedListObj.map((publishedItem, pi) => (
								<div key={publishedItem.id} className="row">
									<div className="col-2">{publishedItem.sended}</div>
									<div className="col-4">{publishedItem.subject}</div>
									<div className="col-6">
									{(publishedItem.products && publishedItem.products.length>-1) && 
											<div className="mb-1">
											{publishedItem.products.map((productkey) => (
												<React.Fragment key={productkey}>
												{(props.productObj && props.productObj.length>-1) &&
													<>
													{props.productObj.map((product, p) => (
														<React.Fragment key={product.id}>
														{(product.id==productkey) &&
															<Chip key={product.id} className={props.classes.productChip} size="small" label={product.NAME} />
														}
														</React.Fragment>
													))}
													</>
												}
											</React.Fragment>
											))}
											</div>
										}
									</div>
								</div>
							))}
						</>
					}
					</div>
				</Grid>
			</Grid>

			<TextField
				id="subject"
				label="Viestin otsikko *"
				variant="outlined"
				className={props.classes.formInputMarginT}
				type="text"
				value={publishObj.subject}
				fullWidth
				error={!isValid && publishObj.subject==""}
				onChange={handlePublishInputChange}
				InputLabelProps={{
					shrink: true,
				}}
			/>	

			<TextField
				id="message"
				label="Viesti *"
				helperText="Viestin perään liitetään alla olevat yhteystiedot."
				variant="outlined"
				className={props.classes.formInputMargin}
				type="text"
				value={publishObj.message}
				fullWidth
				multiline
				rows="4"
				error={!isValid && publishObj.message==""}
				onChange={handlePublishInputChange}
				InputLabelProps={{
					shrink: true,
				}}
			/>	

			<p className={props.classes.muted}>
				{props.organisationData.NAME}<br/>
				{props.organisationData.ORGADDRESS}<br/>
				{props.organisationData.ZIP} {props.organisationData.CITY}<br/>
				{props.organisationData.PHONE}<br/>
				{props.organisationData.EMAIL}<br/>
			</p>

			<hr/>

			<Grid container className={props.classes.bottomGrid}>
				<Grid item xs={8}>
					<Button type="button" variant="contained" color="primary" className={props.classes.saveButton} onClick={handlePublish}>Lähetä ilmoitus</Button>
				</Grid>
			</Grid>	

			{isSaveOk &&
			<Alert severity="success">
				<AlertTitle>Ilmoitus julkaistu</AlertTitle>
				Ilmoitus on nyt tallennettu ja julkaistu.
			</Alert>
			}

			{isError &&
			<Alert severity="error">
				<AlertTitle>Ilmoituksen tallennus epäonnistui</AlertTitle>
				Tarkasta että kaikki tiedot on syötetty ja kokeile uudestaan!
			</Alert>
			}
			
		</>

	);
}
